// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PortfolioWork_portfolio__work_item__hD\\+j5 {
  width: 100%;
  background: var(--card-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  border-radius: 12px;
  gap: 14px;
}
.PortfolioWork_portfolio__work_block__PUv3H {
  width: calc(50% - 7px);
  padding: 20px;
  border-radius: 12px;
  height: 100%;
  background: #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PortfolioWork_portfolio__work_text__ctjfK {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.PortfolioWork_portfolio__work_count__4zixU {
  color: var(--primary);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.PortfolioWork_portfolio__play_icon__p067V {
  cursor: pointer;
}

@media (max-width: 768px) {
  .PortfolioWork_portfolio__work_item__hD\\+j5 {
    flex-direction: column;
  }
  .PortfolioWork_portfolio__work_block__PUv3H {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .PortfolioWork_portfolio__work_block__PUv3H {
    padding: 12px 16px;
    border-radius: 8px;
  }

  .PortfolioWork_portfolio__work_item__hD\\+j5 {
    padding: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/portfolio-work/PortfolioWork.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".portfolio__work_item {\n  width: 100%;\n  background: var(--card-gray);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 14px;\n  border-radius: 12px;\n  gap: 14px;\n}\n.portfolio__work_block {\n  width: calc(50% - 7px);\n  padding: 20px;\n  border-radius: 12px;\n  height: 100%;\n  background: #d2d2d2;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.portfolio__work_text {\n  color: var(--dark);\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 20px;\n}\n.portfolio__work_count {\n  color: var(--primary);\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 20px;\n}\n.portfolio__play_icon {\n  cursor: pointer;\n}\n\n@media (max-width: 768px) {\n  .portfolio__work_item {\n    flex-direction: column;\n  }\n  .portfolio__work_block {\n    width: 100%;\n  }\n}\n\n@media (max-width: 600px) {\n  .portfolio__work_block {\n    padding: 12px 16px;\n    border-radius: 8px;\n  }\n\n  .portfolio__work_item {\n    padding: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portfolio__work_item": `PortfolioWork_portfolio__work_item__hD+j5`,
	"portfolio__work_block": `PortfolioWork_portfolio__work_block__PUv3H`,
	"portfolio__work_text": `PortfolioWork_portfolio__work_text__ctjfK`,
	"portfolio__work_count": `PortfolioWork_portfolio__work_count__4zixU`,
	"portfolio__play_icon": `PortfolioWork_portfolio__play_icon__p067V`
};
export default ___CSS_LOADER_EXPORT___;
