// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeUpload_home__upload_section__IoC-U {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.HomeUpload_home__upload_wrapper__8BU7N {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.HomeUpload_home__upload_heading__SZUiW {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.HomeUpload_home__upload_title__gX2vh {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.HomeUpload_home__upload_subtitle__BlHHC {
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

@media (max-width: 800px) {
  .HomeUpload_home__upload_title__gX2vh {
    font-size: 32px;
    line-height: 36px;
  }
}
@media (max-width: 600px) {
  .HomeUpload_home__upload_title__gX2vh {
    font-size: 26px;
    line-height: 34px;
  }

  .HomeUpload_home__upload_subtitle__BlHHC {
    font-size: 16px;
    line-height: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/home-upload/HomeUpload.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;AACA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".home__upload_section {\n  width: 100%;\n  padding-top: 60px;\n  padding-bottom: 60px;\n}\n.home__upload_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n.home__upload_heading {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 12px;\n}\n.home__upload_title {\n  color: var(--dark);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.home__upload_subtitle {\n  color: var(--dark);\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 22px;\n  text-align: center;\n}\n\n@media (max-width: 800px) {\n  .home__upload_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n@media (max-width: 600px) {\n  .home__upload_title {\n    font-size: 26px;\n    line-height: 34px;\n  }\n\n  .home__upload_subtitle {\n    font-size: 16px;\n    line-height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home__upload_section": `HomeUpload_home__upload_section__IoC-U`,
	"home__upload_wrapper": `HomeUpload_home__upload_wrapper__8BU7N`,
	"home__upload_heading": `HomeUpload_home__upload_heading__SZUiW`,
	"home__upload_title": `HomeUpload_home__upload_title__gX2vh`,
	"home__upload_subtitle": `HomeUpload_home__upload_subtitle__BlHHC`
};
export default ___CSS_LOADER_EXPORT___;
