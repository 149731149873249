// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_policy__section__\\+5gln {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background: var(--black);
}
.PrivacyPolicy_policy__wrapper__f1PpL {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.PrivacyPolicy_policy__wrapper_title__L6Fmn {
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.PrivacyPolicy_policy__wrapper_main__6bBCY {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.PrivacyPolicy_policy__main_text__fw7Xl {
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.PrivacyPolicy_policy__main_subtitle__Vg4xI {
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.PrivacyPolicy_policy__main_control__OYwfe {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.PrivacyPolicy_policy__main_link__BPOVo {
  color: var(--primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .PrivacyPolicy_policy__wrapper_title__L6Fmn {
    font-size: 26px;
    line-height: 34px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/privacy-policy/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;EACpB,wBAAwB;AAC1B;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".policy__section {\n  width: 100%;\n  height: 100%;\n  padding-top: 60px;\n  padding-bottom: 60px;\n  background: var(--black);\n}\n.policy__wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n.policy__wrapper_title {\n  color: var(--white);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.policy__wrapper_main {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n.policy__main_text {\n  color: var(--white);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22px;\n}\n.policy__main_subtitle {\n  color: var(--white);\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 22px;\n}\n.policy__main_control {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n}\n.policy__main_link {\n  color: var(--primary);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22px;\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n@media (max-width: 600px) {\n  .policy__wrapper_title {\n    font-size: 26px;\n    line-height: 34px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"policy__section": `PrivacyPolicy_policy__section__+5gln`,
	"policy__wrapper": `PrivacyPolicy_policy__wrapper__f1PpL`,
	"policy__wrapper_title": `PrivacyPolicy_policy__wrapper_title__L6Fmn`,
	"policy__wrapper_main": `PrivacyPolicy_policy__wrapper_main__6bBCY`,
	"policy__main_text": `PrivacyPolicy_policy__main_text__fw7Xl`,
	"policy__main_subtitle": `PrivacyPolicy_policy__main_subtitle__Vg4xI`,
	"policy__main_control": `PrivacyPolicy_policy__main_control__OYwfe`,
	"policy__main_link": `PrivacyPolicy_policy__main_link__BPOVo`
};
export default ___CSS_LOADER_EXPORT___;
