// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminLayout_admin__content_main__\\+C1I5 {
  width: 80%;
  background: var(--dark-gray);
  border-radius: 0 12px 12px 0;
}

.AdminLayout_admin__wrapper_content__Z6bxp {
  width: 100%;
  display: flex;
}

@media (max-width: 1000px) {
  .AdminLayout_admin__wrapper_content__Z6bxp {
    flex-direction: column;
  }

  .AdminLayout_admin__content_main__\\+C1I5 {
    border-radius: 0 0 12px 12px;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/admin-layout/AdminLayout.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,4BAA4B;EAC5B,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,4BAA4B;IAC5B,WAAW;EACb;AACF","sourcesContent":[".admin__content_main {\n  width: 80%;\n  background: var(--dark-gray);\n  border-radius: 0 12px 12px 0;\n}\n\n.admin__wrapper_content {\n  width: 100%;\n  display: flex;\n}\n\n@media (max-width: 1000px) {\n  .admin__wrapper_content {\n    flex-direction: column;\n  }\n\n  .admin__content_main {\n    border-radius: 0 0 12px 12px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin__content_main": `AdminLayout_admin__content_main__+C1I5`,
	"admin__wrapper_content": `AdminLayout_admin__wrapper_content__Z6bxp`
};
export default ___CSS_LOADER_EXPORT___;
