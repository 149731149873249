// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__block__vCW8P {
  width: 100%;
  min-height: 100%;
  border-radius: 30px;
  background: var(--card-gray);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Card_card__block__vCW8P div {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.Card_card__block__vCW8P h3 {
  color: var(--dark);
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}

.Card_card__block__vCW8P p {
  color: var(--dark);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.Card_card__block__vCW8P ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 14px;
}

.Card_card__block__vCW8P ul li {
  list-style-type: disc;
  color: var(--dark);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.Card_card__block__vCW8P ul li::marker {
  color: var(--primary);
}

@media (max-width: 600px) {
  .Card_card__block__vCW8P {
    border-radius: 20px;
    padding: 24px;
    gap: 10px;
  }

  .Card_card__block__vCW8P h3 {
    font-size: 20px;
  }

  .Card_card__block__vCW8P p {
    line-height: 22px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/card/Card.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,4BAA4B;EAC5B,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,mBAAmB;IACnB,aAAa;IACb,SAAS;EACX;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".card__block {\n  width: 100%;\n  min-height: 100%;\n  border-radius: 30px;\n  background: var(--card-gray);\n  padding: 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.card__block div {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n\n.card__block h3 {\n  color: var(--dark);\n  font-size: 22px;\n  font-weight: 600;\n  line-height: 24px;\n}\n\n.card__block p {\n  color: var(--dark);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n.card__block ul {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding-left: 14px;\n}\n\n.card__block ul li {\n  list-style-type: disc;\n  color: var(--dark);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n.card__block ul li::marker {\n  color: var(--primary);\n}\n\n@media (max-width: 600px) {\n  .card__block {\n    border-radius: 20px;\n    padding: 24px;\n    gap: 10px;\n  }\n\n  .card__block h3 {\n    font-size: 20px;\n  }\n\n  .card__block p {\n    line-height: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card__block": `Card_card__block__vCW8P`
};
export default ___CSS_LOADER_EXPORT___;
