// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

ul,
ol,
li {
  list-style: none;
}

._base_container__3rype {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1280px) {
  section {
    padding: 0 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/utils/styles/_base.css"],"names":[],"mappings":"AAAA;;;EAGE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["*,\n*::before,\n*::after {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Montserrat\", sans-serif;\n}\n\nhtml,\nbody {\n  height: 100%;\n}\n\na {\n  text-decoration: none;\n}\n\nbutton {\n  cursor: pointer;\n}\n\nul,\nol,\nli {\n  list-style: none;\n}\n\n.container {\n  max-width: 1200px;\n  margin: 0 auto;\n}\n\n@media (max-width: 1280px) {\n  section {\n    padding: 0 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_base_container__3rype`
};
export default ___CSS_LOADER_EXPORT___;
