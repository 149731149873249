// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Admin_admin__section__CPX-J {
  width: 100%;
  height: 100%;
  padding: 20px 0 100px 0;
  background: var(--black);
}
.Admin_container__gXV82 {
  max-width: 1200px;
  margin: 0 auto;
}
.Admin_admin__wrapper__H8Gax {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.Admin_admin__wrapper_route__zBkTx {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.Admin_admin__router_icon__w0rTa:hover {
  cursor: pointer;
}
.Admin_admin__router_icon__w0rTa:hover path {
  stroke: var(--primary);
}
.Admin_admin__router_name__vaa7- {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.Admin_admin__router_name__vaa7-:hover {
  color: var(--primary);
}
.Admin_admin__router_active__lvoo9 {
  color: var(--primary);
}
.Admin_admin__wrapper_inner__zz-tU {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.Admin_admin__inner_title__1biH0 {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: var(--white);
}

@media (max-width: 1200px) {
  .Admin_admin__section__CPX-J {
    padding: 20px 15px 100px 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/Admin.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,wBAAwB;AAC1B;AACA;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,eAAe;AACjB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".admin__section {\n  width: 100%;\n  height: 100%;\n  padding: 20px 0 100px 0;\n  background: var(--black);\n}\n.container {\n  max-width: 1200px;\n  margin: 0 auto;\n}\n.admin__wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 60px;\n}\n.admin__wrapper_route {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin-bottom: 10px;\n}\n.admin__router_icon:hover {\n  cursor: pointer;\n}\n.admin__router_icon:hover path {\n  stroke: var(--primary);\n}\n.admin__router_name {\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 12px;\n  color: var(--white);\n  margin-top: 2px;\n  cursor: pointer;\n}\n.admin__router_name:hover {\n  color: var(--primary);\n}\n.admin__router_active {\n  color: var(--primary);\n}\n.admin__wrapper_inner {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 40px;\n}\n.admin__inner_title {\n  font-size: 26px;\n  font-weight: 600;\n  line-height: 30px;\n  text-align: center;\n  color: var(--white);\n}\n\n@media (max-width: 1200px) {\n  .admin__section {\n    padding: 20px 15px 100px 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin__section": `Admin_admin__section__CPX-J`,
	"container": `Admin_container__gXV82`,
	"admin__wrapper": `Admin_admin__wrapper__H8Gax`,
	"admin__wrapper_route": `Admin_admin__wrapper_route__zBkTx`,
	"admin__router_icon": `Admin_admin__router_icon__w0rTa`,
	"admin__router_name": `Admin_admin__router_name__vaa7-`,
	"admin__router_active": `Admin_admin__router_active__lvoo9`,
	"admin__wrapper_inner": `Admin_admin__wrapper_inner__zz-tU`,
	"admin__inner_title": `Admin_admin__inner_title__1biH0`
};
export default ___CSS_LOADER_EXPORT___;
