// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogDetailsMore_blog__more_section__Ioz45 {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
}
.BlogDetailsMore_blog__more_wrapper__meru4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/blog/components/blog-details/components/blog-details-more/BlogDetailsMore.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".blog__more_section {\n  width: 100%;\n  height: 100%;\n  padding-bottom: 100px;\n}\n.blog__more_wrapper {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  column-gap: 18px;\n  row-gap: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blog__more_section": `BlogDetailsMore_blog__more_section__Ioz45`,
	"blog__more_wrapper": `BlogDetailsMore_blog__more_wrapper__meru4`
};
export default ___CSS_LOADER_EXPORT___;
