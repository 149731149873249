// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeAbout_home__about_section__ewtda {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.HomeAbout_home__about_wrapper__RmYKD {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.HomeAbout_home__about_title__5Zciy {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.HomeAbout_home__about_main__GyeFg {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.HomeAbout_home__main_banner__VWUr8 {
  width: 100%;
  display: flex;
  gap: 30px;
}
.HomeAbout_home__banner_item__-mb4C {
  width: calc(50% - 15px);
}
.HomeAbout_home__banner_image__zP6G6 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.HomeAbout_home__banner_card__xW9Tt {
  width: calc(50% - 15px);
}

@media (max-width: 800px) {
  .HomeAbout_home__main_banner__VWUr8 {
    flex-direction: column;
  }
  .HomeAbout_home__banner_item__-mb4C,
  .HomeAbout_home__banner_card__xW9Tt {
    width: 100%;
  }
  .HomeAbout_home__about_title__5Zciy {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .HomeAbout_home__about_main__GyeFg,
  .HomeAbout_home__main_banner__VWUr8 {
    gap: 15px;
  }
  .HomeAbout_home__about_title__5Zciy {
    font-size: 26px;
    line-height: 34px;
  }
  .HomeAbout_home__banner_image__zP6G6 {
    border-radius: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/home-about/HomeAbout.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,WAAW;EACX,aAAa;EACb,SAAS;AACX;AACA;EACE,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;;IAEE,WAAW;EACb;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;;IAEE,SAAS;EACX;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".home__about_section {\n  width: 100%;\n  height: 100%;\n  padding-top: 60px;\n  padding-bottom: 60px;\n}\n.home__about_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n}\n.home__about_title {\n  color: var(--dark);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.home__about_main {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n}\n.home__main_banner {\n  width: 100%;\n  display: flex;\n  gap: 30px;\n}\n.home__banner_item {\n  width: calc(50% - 15px);\n}\n.home__banner_image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 30px;\n}\n.home__banner_card {\n  width: calc(50% - 15px);\n}\n\n@media (max-width: 800px) {\n  .home__main_banner {\n    flex-direction: column;\n  }\n  .home__banner_item,\n  .home__banner_card {\n    width: 100%;\n  }\n  .home__about_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: 600px) {\n  .home__about_main,\n  .home__main_banner {\n    gap: 15px;\n  }\n  .home__about_title {\n    font-size: 26px;\n    line-height: 34px;\n  }\n  .home__banner_image {\n    border-radius: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home__about_section": `HomeAbout_home__about_section__ewtda`,
	"home__about_wrapper": `HomeAbout_home__about_wrapper__RmYKD`,
	"home__about_title": `HomeAbout_home__about_title__5Zciy`,
	"home__about_main": `HomeAbout_home__about_main__GyeFg`,
	"home__main_banner": `HomeAbout_home__main_banner__VWUr8`,
	"home__banner_item": `HomeAbout_home__banner_item__-mb4C`,
	"home__banner_image": `HomeAbout_home__banner_image__zP6G6`,
	"home__banner_card": `HomeAbout_home__banner_card__xW9Tt`
};
export default ___CSS_LOADER_EXPORT___;
