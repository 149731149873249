// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogDetails_blog__section_error__y2piV {
  width: 100%;
  height: 80vh;
}
.BlogDetails_blog__section_error__y2piV > div {
  height: 100%;
}
.BlogDetails_blog__error_wrapper__7XIes {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 28px;
}
.BlogDetails_blog__error_text__LmgBU {
  color: var(--black);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}

@media (max-width: 800px) {
  .BlogDetails_blog__error_text__LmgBU {
    font-size: 24px;
    line-height: 26px;
  }
}

@media (max-width: 600px) {
  .BlogDetails_blog__error_text__LmgBU {
    font-size: 20px;
    line-height: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/blog/components/blog-details/BlogDetails.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".blog__section_error {\n  width: 100%;\n  height: 80vh;\n}\n.blog__section_error > div {\n  height: 100%;\n}\n.blog__error_wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 28px;\n}\n.blog__error_text {\n  color: var(--black);\n  font-size: 28px;\n  font-weight: 700;\n  line-height: 32px;\n  text-align: center;\n}\n\n@media (max-width: 800px) {\n  .blog__error_text {\n    font-size: 24px;\n    line-height: 26px;\n  }\n}\n\n@media (max-width: 600px) {\n  .blog__error_text {\n    font-size: 20px;\n    line-height: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blog__section_error": `BlogDetails_blog__section_error__y2piV`,
	"blog__error_wrapper": `BlogDetails_blog__error_wrapper__7XIes`,
	"blog__error_text": `BlogDetails_blog__error_text__LmgBU`
};
export default ___CSS_LOADER_EXPORT___;
