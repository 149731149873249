// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePayment_home__payment_section__JwnVq {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
}
.HomePayment_home__payment_wrapper__itY6M {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.HomePayment_home__payment_title__Iw2ls {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.HomePayment_home__payment_main__UjI8\\+ {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.HomePayment_home__payment_inner__bcgy9 {
  width: 100%;
  display: flex;
  gap: 30px;
}
.HomePayment_home__payment_inner__bcgy9 div {
  width: calc(50% - 15px);
}
.HomePayment_home__payment_banner__6cAE3 {
  width: calc(50% - 15px);
}
.HomePayment_home__banner_image__lSA7k {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

@media (max-width: 800px) {
  .HomePayment_home__payment_inner__bcgy9 {
    flex-direction: column;
  }
  .HomePayment_home__payment_inner__bcgy9 div,
  .HomePayment_home__payment_banner__6cAE3 {
    width: 100%;
  }
  .HomePayment_home__payment_title__Iw2ls {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .HomePayment_home__payment_inner__bcgy9,
  .HomePayment_home__payment_main__UjI8\\+ {
    gap: 15px;
  }
  .HomePayment_home__banner_image__lSA7k {
    border-radius: 20px;
  }
  .HomePayment_home__payment_title__Iw2ls {
    font-size: 26px;
    line-height: 34px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/home-payment/HomePayment.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,WAAW;EACX,aAAa;EACb,SAAS;AACX;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;;IAEE,WAAW;EACb;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;;IAEE,SAAS;EACX;EACA;IACE,mBAAmB;EACrB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".home__payment_section {\n  width: 100%;\n  padding-top: 60px;\n  padding-bottom: 100px;\n}\n.home__payment_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n}\n.home__payment_title {\n  color: var(--dark);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.home__payment_main {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n}\n.home__payment_inner {\n  width: 100%;\n  display: flex;\n  gap: 30px;\n}\n.home__payment_inner div {\n  width: calc(50% - 15px);\n}\n.home__payment_banner {\n  width: calc(50% - 15px);\n}\n.home__banner_image {\n  width: 100%;\n  height: 100%;\n  border-radius: 30px;\n}\n\n@media (max-width: 800px) {\n  .home__payment_inner {\n    flex-direction: column;\n  }\n  .home__payment_inner div,\n  .home__payment_banner {\n    width: 100%;\n  }\n  .home__payment_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: 600px) {\n  .home__payment_inner,\n  .home__payment_main {\n    gap: 15px;\n  }\n  .home__banner_image {\n    border-radius: 20px;\n  }\n  .home__payment_title {\n    font-size: 26px;\n    line-height: 34px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home__payment_section": `HomePayment_home__payment_section__JwnVq`,
	"home__payment_wrapper": `HomePayment_home__payment_wrapper__itY6M`,
	"home__payment_title": `HomePayment_home__payment_title__Iw2ls`,
	"home__payment_main": `HomePayment_home__payment_main__UjI8+`,
	"home__payment_inner": `HomePayment_home__payment_inner__bcgy9`,
	"home__payment_banner": `HomePayment_home__payment_banner__6cAE3`,
	"home__banner_image": `HomePayment_home__banner_image__lSA7k`
};
export default ___CSS_LOADER_EXPORT___;
