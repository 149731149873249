// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #ee701f;
  --white: #ffffff;
  --black: #1d1d1b;
  --dark-gray: #292929;
  --border-light-gray: #93939380;
  --border-gray: #939393;
  --white-gray: #ffffffb3;
  --gray: #666b7a;
  --card-gray: #f2f2f2;
  --light-dark: #2e2e2c;
  --size-dark: #20201f;
  --line-gray: #515150;
  --input-gray: #f0f2f5;
}
`, "",{"version":3,"sources":["webpack://./src/utils/styles/_variables.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;EACpB,8BAA8B;EAC9B,sBAAsB;EACtB,uBAAuB;EACvB,eAAe;EACf,oBAAoB;EACpB,qBAAqB;EACrB,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;AACvB","sourcesContent":[":root {\n  --primary: #ee701f;\n  --white: #ffffff;\n  --black: #1d1d1b;\n  --dark-gray: #292929;\n  --border-light-gray: #93939380;\n  --border-gray: #939393;\n  --white-gray: #ffffffb3;\n  --gray: #666b7a;\n  --card-gray: #f2f2f2;\n  --light-dark: #2e2e2c;\n  --size-dark: #20201f;\n  --line-gray: #515150;\n  --input-gray: #f0f2f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
