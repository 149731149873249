// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminSidebar_admin__content_sidebar__jydCj {
  width: 20%;
  background: var(--dark-gray);
  border-radius: 12px 0 0 12px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.AdminSidebar_admin__sidebar_wrapper__-UPxe {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.AdminSidebar_admin__wrapper_pages__1UkXR {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.AdminSidebar_admin__wrapper_item__vWdJx {
  width: 100%;
  background: transparent;
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--white);
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
}
.AdminSidebar_admin__wrapper_item__vWdJx:hover {
  background: var(--primary);
  color: var(--dark);
}
.AdminSidebar_admin__wrapper_item__vWdJx.AdminSidebar_active__-gfXd {
  background: var(--primary);
  color: var(--dark);
}
.AdminSidebar_admin__wrapper_logout__\\+qTFI {
  width: 100%;
}

@media (max-width: 1000px) {
  .AdminSidebar_admin__content_sidebar__jydCj {
    width: 100%;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-right: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/admin-sidebar/AdminSidebar.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,4BAA4B;EAC5B,4BAA4B;EAC5B,gDAAgD;AAClD;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,oBAAoB;EACpB,iDAAiD;AACnD;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;;AAEA;EACE;IACE,WAAW;IACX,4BAA4B;IAC5B,iDAAiD;IACjD,kBAAkB;EACpB;AACF","sourcesContent":[".admin__content_sidebar {\n  width: 20%;\n  background: var(--dark-gray);\n  border-radius: 12px 0 0 12px;\n  border-right: 1px solid rgba(255, 255, 255, 0.5);\n}\n.admin__sidebar_wrapper {\n  padding: 40px 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n.admin__wrapper_pages {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding-bottom: 20px;\n  border-bottom: 1px solid rgba(255, 255, 255, 0.5);\n}\n.admin__wrapper_item {\n  width: 100%;\n  background: transparent;\n  border-radius: 8px;\n  padding: 14px 20px;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 16px;\n  color: var(--white);\n  text-align: center;\n  transition: 0.4s;\n  cursor: pointer;\n}\n.admin__wrapper_item:hover {\n  background: var(--primary);\n  color: var(--dark);\n}\n.admin__wrapper_item.active {\n  background: var(--primary);\n  color: var(--dark);\n}\n.admin__wrapper_logout {\n  width: 100%;\n}\n\n@media (max-width: 1000px) {\n  .admin__content_sidebar {\n    width: 100%;\n    border-radius: 12px 12px 0 0;\n    border-bottom: 1px solid rgba(255, 255, 255, 0.5);\n    border-right: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin__content_sidebar": `AdminSidebar_admin__content_sidebar__jydCj`,
	"admin__sidebar_wrapper": `AdminSidebar_admin__sidebar_wrapper__-UPxe`,
	"admin__wrapper_pages": `AdminSidebar_admin__wrapper_pages__1UkXR`,
	"admin__wrapper_item": `AdminSidebar_admin__wrapper_item__vWdJx`,
	"active": `AdminSidebar_active__-gfXd`,
	"admin__wrapper_logout": `AdminSidebar_admin__wrapper_logout__+qTFI`
};
export default ___CSS_LOADER_EXPORT___;
