// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeBlog_home__blog_section__0vzqX {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background: var(--black);
  margin-bottom: 100px;
}
.HomeBlog_home__blog_wrapper__2qUJV {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.HomeBlog_home__blog_title__Wh7gK {
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.HomeBlog_home__blog_main__bZ2ae {
  width: 100%;
  position: relative;
}

@media (max-width: 800px) {
  .HomeBlog_home__blog_title__Wh7gK {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .HomeBlog_home__blog_section__0vzqX {
    margin-bottom: 60px;
  }
  .HomeBlog_home__blog_title__Wh7gK {
    font-size: 26px;
    line-height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/home-blog/HomeBlog.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,wBAAwB;EACxB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".home__blog_section {\n  width: 100%;\n  padding-top: 60px;\n  padding-bottom: 60px;\n  background: var(--black);\n  margin-bottom: 100px;\n}\n.home__blog_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n.home__blog_title {\n  color: var(--white);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.home__blog_main {\n  width: 100%;\n  position: relative;\n}\n\n@media (max-width: 800px) {\n  .home__blog_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: 600px) {\n  .home__blog_section {\n    margin-bottom: 60px;\n  }\n  .home__blog_title {\n    font-size: 26px;\n    line-height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home__blog_section": `HomeBlog_home__blog_section__0vzqX`,
	"home__blog_wrapper": `HomeBlog_home__blog_wrapper__2qUJV`,
	"home__blog_title": `HomeBlog_home__blog_title__Wh7gK`,
	"home__blog_main": `HomeBlog_home__blog_main__bZ2ae`
};
export default ___CSS_LOADER_EXPORT___;
