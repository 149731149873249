// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesInfo_services__info_section__AaQqo {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.ServicesInfo_services__info_wrapper__g-vgs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.ServicesInfo_services__info_title__WElUN {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.ServicesInfo_services__info_main__jc5de {
  width: 100%;
  display: flex;
  gap: 30px;
}
.ServicesInfo_services__main_banner__YuUo\\+ {
  width: calc(50% - 15px);
}
.ServicesInfo_services__banner_image__urIXW {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.ServicesInfo_services__main_inner__6d9GX {
  width: calc(50% - 15px);
}

@media (max-width: 1000px) {
  .ServicesInfo_services__info_main__jc5de {
    flex-direction: column;
  }
  .ServicesInfo_services__main_inner__6d9GX,
  .ServicesInfo_services__main_banner__YuUo\\+ {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .ServicesInfo_services__info_title__WElUN {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .ServicesInfo_services__info_title__WElUN {
    font-size: 26px;
    line-height: 34px;
  }
  .ServicesInfo_services__info_main__jc5de {
    gap: 15px;
  }
  .ServicesInfo_services__banner_image__urIXW {
    border-radius: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/services/components/services-info/ServicesInfo.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,aAAa;EACb,SAAS;AACX;AACA;EACE,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;;IAEE,WAAW;EACb;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,SAAS;EACX;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".services__info_section {\n  width: 100%;\n  height: 100%;\n  padding-top: 60px;\n  padding-bottom: 60px;\n}\n.services__info_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n}\n.services__info_title {\n  color: var(--dark);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.services__info_main {\n  width: 100%;\n  display: flex;\n  gap: 30px;\n}\n.services__main_banner {\n  width: calc(50% - 15px);\n}\n.services__banner_image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 30px;\n}\n.services__main_inner {\n  width: calc(50% - 15px);\n}\n\n@media (max-width: 1000px) {\n  .services__info_main {\n    flex-direction: column;\n  }\n  .services__main_inner,\n  .services__main_banner {\n    width: 100%;\n  }\n}\n\n@media (max-width: 800px) {\n  .services__info_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: 600px) {\n  .services__info_title {\n    font-size: 26px;\n    line-height: 34px;\n  }\n  .services__info_main {\n    gap: 15px;\n  }\n  .services__banner_image {\n    border-radius: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services__info_section": `ServicesInfo_services__info_section__AaQqo`,
	"services__info_wrapper": `ServicesInfo_services__info_wrapper__g-vgs`,
	"services__info_title": `ServicesInfo_services__info_title__WElUN`,
	"services__info_main": `ServicesInfo_services__info_main__jc5de`,
	"services__main_banner": `ServicesInfo_services__main_banner__YuUo+`,
	"services__banner_image": `ServicesInfo_services__banner_image__urIXW`,
	"services__main_inner": `ServicesInfo_services__main_inner__6d9GX`
};
export default ___CSS_LOADER_EXPORT___;
