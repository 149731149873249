// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoaderFiles_loader__xJf9C {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoaderFiles_loader__wrapper__ID7mX {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
}

.LoaderFiles_loader__text__sS8dA {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/loader-files/LoaderFiles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".loader {\n  width: 100%;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: var(--black);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.loader__wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 60px;\n}\n\n.loader__text {\n  font-size: 16px;\n  font-weight: 400;\n  color: var(--white);\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `LoaderFiles_loader__xJf9C`,
	"loader__wrapper": `LoaderFiles_loader__wrapper__ID7mX`,
	"loader__text": `LoaderFiles_loader__text__sS8dA`
};
export default ___CSS_LOADER_EXPORT___;
