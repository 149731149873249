// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminBlog_admin__main_blogs__\\+gXAf {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.AdminBlog_admin__blogs_form__Jt8Nl {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AdminBlog_admin__form_show__92JyN {
  background: var(--primary);
  border-radius: 12px;
  border: 2px solid var(--primary);
  outline: none;
  cursor: pointer;
  padding: 16px 48px;
  font-size: 20px;
  font-weight: 400;
  color: var(--dark);
  transition: 0.4s;
  box-shadow: 0 24px 46px rgba(240, 92, 0, 0.332);
  -webkit-user-select: none;
          user-select: none;
  text-transform: uppercase;
}
.AdminBlog_admin__form_show__92JyN:hover {
  background: transparent;
  color: var(--primary);
}
.AdminBlog_admin__blogs_table__IYPVI {
  width: 100%;
}

@media (max-width: 1000px) {
  .AdminBlog_admin__main_blogs__\\+gXAf {
    padding: 40px 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/admin-blog/AdminBlog.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,gCAAgC;EAChC,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,+CAA+C;EAC/C,yBAAiB;UAAjB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".admin__main_blogs {\n  width: 100%;\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  gap: 60px;\n}\n.admin__blogs_form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.admin__form_show {\n  background: var(--primary);\n  border-radius: 12px;\n  border: 2px solid var(--primary);\n  outline: none;\n  cursor: pointer;\n  padding: 16px 48px;\n  font-size: 20px;\n  font-weight: 400;\n  color: var(--dark);\n  transition: 0.4s;\n  box-shadow: 0 24px 46px rgba(240, 92, 0, 0.332);\n  user-select: none;\n  text-transform: uppercase;\n}\n.admin__form_show:hover {\n  background: transparent;\n  color: var(--primary);\n}\n.admin__blogs_table {\n  width: 100%;\n}\n\n@media (max-width: 1000px) {\n  .admin__main_blogs {\n    padding: 40px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin__main_blogs": `AdminBlog_admin__main_blogs__+gXAf`,
	"admin__blogs_form": `AdminBlog_admin__blogs_form__Jt8Nl`,
	"admin__form_show": `AdminBlog_admin__form_show__92JyN`,
	"admin__blogs_table": `AdminBlog_admin__blogs_table__IYPVI`
};
export default ___CSS_LOADER_EXPORT___;
