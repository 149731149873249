// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogMain_blog__main_section__RFeYG {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.BlogMain_blog__main_wrapper__Kq\\+9d {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.BlogMain_blog__main_title__cdTsx {
  color: var(--black);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.BlogMain_blog__main_block__q42LK {
  width: 100%;
}
.BlogMain_blog__block_list__o3YLN {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 60px;
}

@media (max-width: 800px) {
  .BlogMain_blog__main_title__cdTsx {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .BlogMain_blog__main_title__cdTsx {
    font-size: 26px;
    line-height: 34px;
  }

  .BlogMain_blog__main_wrapper__Kq\\+9d {
    gap: 28px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/blog/components/blog-main/BlogMain.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,SAAS;EACX;AACF","sourcesContent":[".blog__main_section {\n  width: 100%;\n  height: 100%;\n  padding-top: 60px;\n  padding-bottom: 60px;\n}\n.blog__main_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n.blog__main_title {\n  color: var(--black);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.blog__main_block {\n  width: 100%;\n}\n.blog__block_list {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  column-gap: 18px;\n  row-gap: 60px;\n}\n\n@media (max-width: 800px) {\n  .blog__main_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: 600px) {\n  .blog__main_title {\n    font-size: 26px;\n    line-height: 34px;\n  }\n\n  .blog__main_wrapper {\n    gap: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blog__main_section": `BlogMain_blog__main_section__RFeYG`,
	"blog__main_wrapper": `BlogMain_blog__main_wrapper__Kq+9d`,
	"blog__main_title": `BlogMain_blog__main_title__cdTsx`,
	"blog__main_block": `BlogMain_blog__main_block__q42LK`,
	"blog__block_list": `BlogMain_blog__block_list__o3YLN`
};
export default ___CSS_LOADER_EXPORT___;
