// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_home__about_button__PlG5U {
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px 32px;
  background: var(--primary);
  border: none;
  outline: none;
  border-radius: 12px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  box-shadow: 0 2px 32px 0 var(--primary);
  transition: 0.4s;
}

.Button_home__about_button__PlG5U:hover {
  transform: scale(1.05);
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/button/Button.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;EAClB,0BAA0B;EAC1B,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".home__about_button {\n  width: fit-content;\n  padding: 16px 32px;\n  background: var(--primary);\n  border: none;\n  outline: none;\n  border-radius: 12px;\n  color: var(--white);\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 18px;\n  box-shadow: 0 2px 32px 0 var(--primary);\n  transition: 0.4s;\n}\n\n.home__about_button:hover {\n  transform: scale(1.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home__about_button": `Button_home__about_button__PlG5U`
};
export default ___CSS_LOADER_EXPORT___;
