// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesAdvice_services__advice_section__-PAsd {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.ServicesAdvice_services__advice_wrapper__7dQd2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.ServicesAdvice_servicse__advice_title__RViZG {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.ServicesAdvice_services__advice_main__TABIA {
  width: 100%;
}

@media (max-width: 800px) {
  .ServicesAdvice_servicse__advice_title__RViZG {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .ServicesAdvice_servicse__advice_title__RViZG {
    font-size: 26px;
    line-height: 34px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/services/components/services-advice/ServicesAdvice.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".services__advice_section {\n  width: 100%;\n  height: 100%;\n  padding-top: 60px;\n  padding-bottom: 60px;\n}\n.services__advice_wrapper {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 40px;\n}\n.servicse__advice_title {\n  color: var(--dark);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.services__advice_main {\n  width: 100%;\n}\n\n@media (max-width: 800px) {\n  .servicse__advice_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: 600px) {\n  .servicse__advice_title {\n    font-size: 26px;\n    line-height: 34px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services__advice_section": `ServicesAdvice_services__advice_section__-PAsd`,
	"services__advice_wrapper": `ServicesAdvice_services__advice_wrapper__7dQd2`,
	"servicse__advice_title": `ServicesAdvice_servicse__advice_title__RViZG`,
	"services__advice_main": `ServicesAdvice_services__advice_main__TABIA`
};
export default ___CSS_LOADER_EXPORT___;
