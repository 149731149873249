// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../public/images/portfolio-banner.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PortfolioBanner_portfolio__banner_section__p-\\+D9 {
  width: 100%;
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.PortfolioBanner_portfolio__banner_section__p-\\+D9 > div {
  height: 100%;
}
.PortfolioBanner_portfolio__banner_wrapper__RBNsq {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.PortfolioBanner_portfolio__banner_title__b9l02 {
  width: 78%;
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.PortfolioBanner_portfolio__banner_text__A\\+WVq {
  width: 78%;
  color: var(--white);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 800px) {
  .PortfolioBanner_portfolio__banner_title__b9l02 {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .PortfolioBanner_portfolio__banner_title__b9l02 {
    font-size: 26px;
    line-height: 34px;
  }
  .PortfolioBanner_portfolio__banner_text__A\\+WVq {
    font-size: 16px;
    line-height: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/portfolio/components/portfolio-banner/PortfolioBanner.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yDAA0E;EAC1E,sBAAsB;EACtB,kCAAkC;EAClC,4BAA4B;AAC9B;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,UAAU;EACV,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".portfolio__banner_section {\n  width: 100%;\n  height: 100vh;\n  background-image: url(\"../../../../../public/images/portfolio-banner.jpg\");\n  background-size: cover;\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n.portfolio__banner_section > div {\n  height: 100%;\n}\n.portfolio__banner_wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 30px;\n}\n.portfolio__banner_title {\n  width: 78%;\n  color: var(--white);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.portfolio__banner_text {\n  width: 78%;\n  color: var(--white);\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 24px;\n  text-align: center;\n}\n\n@media (max-width: 800px) {\n  .portfolio__banner_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: 600px) {\n  .portfolio__banner_title {\n    font-size: 26px;\n    line-height: 34px;\n  }\n  .portfolio__banner_text {\n    font-size: 16px;\n    line-height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portfolio__banner_section": `PortfolioBanner_portfolio__banner_section__p-+D9`,
	"portfolio__banner_wrapper": `PortfolioBanner_portfolio__banner_wrapper__RBNsq`,
	"portfolio__banner_title": `PortfolioBanner_portfolio__banner_title__b9l02`,
	"portfolio__banner_text": `PortfolioBanner_portfolio__banner_text__A+WVq`
};
export default ___CSS_LOADER_EXPORT___;
