// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CartUpload_cart__upload_section__VDYNZ {
  width: 100%;
  height: 100%;
  background: var(--black);
  padding-top: 60px;
  padding-bottom: 60px;
}
.CartUpload_cart__upload_wrapper__MJlNX {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 60px;
}
.CartUpload_cart__actions__b5mH8 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}
.CartUpload_cart__button__3ywme {
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px 32px;
  background: var(--primary);
  border: none;
  outline: none;
  border-radius: 12px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  box-shadow: 0 2px 32px 0 var(--primary);
  transition: 0.4s;
}

.CartUpload_cart__button__3ywme:hover {
  transform: scale(1.05);
}

.CartUpload_cart__button__3ywme.CartUpload_error__c218M {
  background: #924515;
  box-shadow: 0 2px 32px 0 #924515;
}

.CartUpload_cart__fields_error__POngD {
  color: #eb001b;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/cart/cart-upload/CartUpload.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,SAAS;AACX;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,SAAS;AACX;AACA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;EAClB,0BAA0B;EAC1B,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".cart__upload_section {\n  width: 100%;\n  height: 100%;\n  background: var(--black);\n  padding-top: 60px;\n  padding-bottom: 60px;\n}\n.cart__upload_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 60px;\n}\n.cart__actions {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 20px;\n}\n.cart__button {\n  width: fit-content;\n  padding: 16px 32px;\n  background: var(--primary);\n  border: none;\n  outline: none;\n  border-radius: 12px;\n  color: var(--white);\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 18px;\n  box-shadow: 0 2px 32px 0 var(--primary);\n  transition: 0.4s;\n}\n\n.cart__button:hover {\n  transform: scale(1.05);\n}\n\n.cart__button.error {\n  background: #924515;\n  box-shadow: 0 2px 32px 0 #924515;\n}\n\n.cart__fields_error {\n  color: #eb001b;\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cart__upload_section": `CartUpload_cart__upload_section__VDYNZ`,
	"cart__upload_wrapper": `CartUpload_cart__upload_wrapper__MJlNX`,
	"cart__actions": `CartUpload_cart__actions__b5mH8`,
	"cart__button": `CartUpload_cart__button__3ywme`,
	"error": `CartUpload_error__c218M`,
	"cart__fields_error": `CartUpload_cart__fields_error__POngD`
};
export default ___CSS_LOADER_EXPORT___;
