// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogItem_blog__list_item__2bZR5 {
  width: calc(33.33% - 12px);
  height: 560px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.BlogItem_blog__item_banner__0v8g8 {
  width: 100%;
  height: 50%;
}
.BlogItem_blog__banner_item__aKsTD {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.BlogItem_blog__item_info__xS5Lm {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.BlogItem_blog__info_wrapper__J5Nyt {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.BlogItem_blog__info_title__xx9YG {
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.BlogItem_blog__info_description__RhT4z {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.BlogItem_blog__item_info__xS5Lm > button {
  margin-top: 10px;
}

@media (max-width: 1000px) {
  .BlogItem_blog__list_item__2bZR5 {
    width: calc(50% - 9px);
  }
}

@media (max-width: 500px) {
  .BlogItem_blog__list_item__2bZR5 {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/blog-item/BlogItem.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,WAAW;EACX,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".blog__list_item {\n  width: calc(33.33% - 12px);\n  height: 560px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.blog__item_banner {\n  width: 100%;\n  height: 50%;\n}\n.blog__banner_item {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 12px;\n}\n.blog__item_info {\n  width: 100%;\n  height: 50%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.blog__info_wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.blog__info_title {\n  color: var(--black);\n  font-size: 20px;\n  font-weight: 700;\n  line-height: 24px;\n}\n.blog__info_description {\n  color: var(--black);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 20px;\n}\n\n.blog__item_info > button {\n  margin-top: 10px;\n}\n\n@media (max-width: 1000px) {\n  .blog__list_item {\n    width: calc(50% - 9px);\n  }\n}\n\n@media (max-width: 500px) {\n  .blog__list_item {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blog__list_item": `BlogItem_blog__list_item__2bZR5`,
	"blog__item_banner": `BlogItem_blog__item_banner__0v8g8`,
	"blog__banner_item": `BlogItem_blog__banner_item__aKsTD`,
	"blog__item_info": `BlogItem_blog__item_info__xS5Lm`,
	"blog__info_wrapper": `BlogItem_blog__info_wrapper__J5Nyt`,
	"blog__info_title": `BlogItem_blog__info_title__xx9YG`,
	"blog__info_description": `BlogItem_blog__info_description__RhT4z`
};
export default ___CSS_LOADER_EXPORT___;
