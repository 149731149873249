// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePortfolio_home__portfolio_section__o1wEX {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background: var(--black);
}
.HomePortfolio_home__portfolio_wrapper__ipZoe {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.HomePortfolio_home__portfolio_title__fn-i2 {
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.HomePortfolio_home__portfolio_main__IVlaI {
  width: 100%;
  position: relative;
}
.HomePortfolio_home__portfolio_button__tNh7C {
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px 32px;
  background: var(--primary);
  border: none;
  outline: none;
  border-radius: 12px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  box-shadow: 0 2px 32px 0 var(--primary);
  transition: 0.4s;
}
.HomePortfolio_home__portfolio_button__tNh7C:hover {
  transform: scale(1.05);
}

@media (max-width: 800px) {
  .HomePortfolio_home__portfolio_title__fn-i2 {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .HomePortfolio_home__portfolio_title__fn-i2 {
    font-size: 26px;
    line-height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/home-portfolio/HomePortfolio.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,wBAAwB;AAC1B;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;EAClB,0BAA0B;EAC1B,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uCAAuC;EACvC,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".home__portfolio_section {\n  width: 100%;\n  padding-top: 60px;\n  padding-bottom: 60px;\n  background: var(--black);\n}\n.home__portfolio_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n}\n.home__portfolio_title {\n  color: var(--white);\n  font-size: 36px;\n  font-weight: 700;\n  line-height: 40px;\n  text-align: center;\n}\n.home__portfolio_main {\n  width: 100%;\n  position: relative;\n}\n.home__portfolio_button {\n  width: fit-content;\n  padding: 16px 32px;\n  background: var(--primary);\n  border: none;\n  outline: none;\n  border-radius: 12px;\n  color: var(--white);\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 18px;\n  box-shadow: 0 2px 32px 0 var(--primary);\n  transition: 0.4s;\n}\n.home__portfolio_button:hover {\n  transform: scale(1.05);\n}\n\n@media (max-width: 800px) {\n  .home__portfolio_title {\n    font-size: 32px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: 600px) {\n  .home__portfolio_title {\n    font-size: 26px;\n    line-height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home__portfolio_section": `HomePortfolio_home__portfolio_section__o1wEX`,
	"home__portfolio_wrapper": `HomePortfolio_home__portfolio_wrapper__ipZoe`,
	"home__portfolio_title": `HomePortfolio_home__portfolio_title__fn-i2`,
	"home__portfolio_main": `HomePortfolio_home__portfolio_main__IVlaI`,
	"home__portfolio_button": `HomePortfolio_home__portfolio_button__tNh7C`
};
export default ___CSS_LOADER_EXPORT___;
